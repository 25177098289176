import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import { ReactComponent as IconTwitter } from './assets/icons/twitter.svg';
import HappyHourSign from './assets/images/happy-hour-sign.png'; 
import "./App.css";
import { initGA } from './analytics';
import ReactGA from 'react-ga4';


const App = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [inputClicked, setInputClicked] = useState(false);

  useEffect(() => {
    initGA(); // Initialize Google Analytics
  }, []);

  const handleInputChange = (event) => {
    setEmail(event.target.value);

    // Track the first click on the input
    if (!inputClicked) {
      setInputClicked(true);
      ReactGA.event({
        category: 'Form Interaction',
        action: 'First Input Click',
        label: 'User clicked into input for the first time'
      });
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/subscribe`, { email });
      setMessage(response.data.message);
      setEmail('');
      ReactGA.event({
        category: 'Form Interaction',
        action: 'Form Submitted',
        label: `Form submitted successfully`
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setMessage(error.response.data.message);
      } else {
        setMessage('Error subscribing, please try again.');
      }
      ReactGA.event({
        category: 'Form Interaction',
        action: 'Form Submitted',
        label: `Form submitted with error`
      });
    }
  };

  return (
    <div className="card">
      <div className="header">
        <img src={HappyHourSign} alt="Happy Hour Sign" className='happy-hour-sign'/> 
      </div>
      <div className="content">
        <div className="title-holder">
          <h1>Discover Happy Hours In Chicago</h1>
          <p>Website coming soon. Sign up to be the first to get updates.</p>
        </div>
        <form onSubmit={handleSubmit} form_id="1" form_name="Emails" form_destination="/api/subscribe" form_submit_text="Subscribe">
          <input
            className="cta input"
            type="email"
            name="email"
            autoComplete="email"
            placeholder="Email Address"
            value={email}
            onChange={handleInputChange}
            onClick={handleInputChange}
            required
          />
          <button className="cta button" type="submit" >Subscribe</button>
        </form>
        {message && <p className='message'>{message}</p>}
      </div>
      <div className="footer">
        <div className="social">
          {/* <a href="https://twitter.com" title="Twitter" target="_blank" rel="noopener noreferrer"> */}
            {/* <IconTwitter className="icon" /> */}
          {/* </a> */}
        </div>
      </div>
    </div>
  );
}

export default App;
